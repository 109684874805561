<!-- 空页面，负责中转到目标页面 -->
<template>
  <div></div>
</template>
 
<script>
export default {
  name: "refresh",
  data() {
    return {};
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.query.id) {
        vm.$router.replace(from.path + `?id=${from.query.id}`);
      } else if (from.query.AccountType && from.query.ProductID) {
        vm.$router.replace(
          from.path +
            `?AccountType=${from.query.AccountType}&ProductID=${from.query.ProductID}`
        );
      } else {
        vm.$router.replace(from.fullPath);
      }
    });
  },
};
</script>
<style scoped>
</style>